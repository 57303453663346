// // npm install react-i18next i18next --save
// import { initReactI18next } from "react-i18next";
// import i18n from "i18next";
// import en from "../../locales/en/translations.json";
// import kr from "../../locales/kr/translations.json";
// import cn from "../../locales/cn/translations.json";
// import jp from "../../locales/jp/translations.json";

// // eslint-disable-next-line react-hooks/rules-of-hooks
// // i18n
// //   .use(initReactI18next)
// //   .init(
// //     {
// //       fallbackLng: "en",
// //       debug: false,
// //       resources: {
// //         en: { translation: en },
// //         kr: { translation: kr },
// //         cn: { translation: cn },
// //         jp: { translation: jp },
// //       },
// //       ns: ["page"],
// //       interpolation: {
// //         escapeValue: false,
// //       },
// //     },
// //     function (err) {
// //       if (err) console.error(err);
// //     },
// //   )
// //   .catch((error) => {
// //     console.error("i18n error", error);
// //   });

// i18n
//   .use(initReactI18next)
//   .init({
//     fallbackLng: "en",
//     debug: false,
//     resources: {
//       en: { translation: en },
//       kr: { translation: kr },
//       cn: { translation: cn },
//       jp: { translation: jp },
//     },
//     interpolation: {
//       escapeValue: false,
//     },
//   })
//   .catch((error) => {
//     console.error("i18n error", error);
//   });

// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "../../locales/en/translations.json";
import kr from "../../locales/kr/translations.json";
import cn from "../../locales/cn/translations.json";
import jp from "../../locales/jp/translations.json";

i18n
  .use(LanguageDetector) // Use language detector
  .use(initReactI18next) // Bind react-i18next to the instance
  .init({
    fallbackLng: "en", // Default language if none is found
    debug: false,
    resources: {
      en: { translation: en },
      kr: { translation: kr },
      cn: { translation: cn },
      jp: { translation: jp },
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    detection: {
      order: ["cookie", "localStorage", "sessionStorage", "navigator"], // Check in cookie first
      caches: ["cookie"], // Cache the language in cookies
      lookupCookie: "language", // Use the 'language' cookie
    },
  })
  .catch((error) => {
    console.error("i18n error", error);
  });

export default i18n;
