import { useEffect, useState } from "react";
import { userBrowser, userOS } from "../utils/apollo";
import UAParser from "ua-parser-js";

export function useUserAgent() {
  const [userAgent, setUserAgent] = useState<UAParser.IResult | undefined>();
  useEffect(() => {
    const agent = window.navigator.userAgent;
    const parser = new UAParser(agent);
    setUserAgent(parser.getResult());
    const user_os = parser.getOS().name;
    const user_browser = parser.getBrowser().name;
    userBrowser(user_browser);
    sessionStorage.setItem("user_browser", user_browser || "");
    userOS(user_os);
    sessionStorage.setItem("user_os", user_os || "");
  }, []);

  return { userAgent };
}
